const data = [
  {
    id: 1,
    question: "What tools are required for this project?",
    answer:
      "You will need Python, TensorFlow or PyTorch, and Jupyter Notebook.",
  },
  {
    id: 2,
    question: "How do I set up the development environment?",
    answer:
      "Install Python, then use pip to install TensorFlow or PyTorch, and Jupyter Notebook. Detailed instructions are provided in the project documentation.",
  },
  {
    id: 3,
    question: "Can I use a different deep learning framework?",
    answer:
      "Yes, you can use other frameworks like Keras or MXNet, but our tutorials and examples use TensorFlow/PyTorch.",
  },
  {
    id: 4,
    question: "Is a GPU necessary for this project?",
    answer:
      "A GPU is not necessary but highly recommended for faster training of deep learning models.",
  },
  {
    id: 5,
    question: "What datasets are used in this project?",
    answer:
      "We use publicly available chest X-ray datasets such as the NIH Chest X-ray dataset.",
  },
  {
    id: 6,
    question: "How do we evaluate the model performance?",
    answer:
      "We use metrics like accuracy, precision, recall, and F1-score to evaluate the performance of the model.",
  },
];

export default data;
