import Avatar1 from "../../assets/avatar1.jpg";
import Avatar2 from "../../assets/avatar2.jpg";
import Avatar3 from "../../assets/avatar3.jpg";
import Avatar4 from "../../assets/avatar4.jpg";
import Avatar5 from "../../assets/avatar5.jpg";
import Avatar6 from "../../assets/avatar6.jpg";
import Avatar7 from "../../assets/avatar7.jpg";

const data = [
  {
    id: 1,
    quote:
      "The machine learning model you developed exceeded our expectations and delivered outstanding results. Your expertise in data science is unmatched!",
    avatar: Avatar1,
    name: "Resham Joshi",
    profession: "Developer",
  },
  {
    id: 2,
    quote:
      "Fantastic work on the data analysis project! Your insights were incredibly valuable, and the Python scripts were executed perfectly.",
    avatar: Avatar2,
    name: "Vrajesh Vagadia",
    profession: "Web Developer",
  },
  {
    id: 3,
    quote: "Impressive work, buddy. Highly recommend!",
    avatar: Avatar3,
    name: "Jemmish",
    profession: "Backend developer",
  },
  {
    id: 4,
    quote:
      "Wow. You finished earlier than you said. Everything's modern and beautifully done to the T. Great profession!",
    avatar: Avatar4,
    name: "Fatima beg",
    profession: "Mern Developer",
  },
  {
    id: 5,
    quote: "I like how the app turned out. You are incredible, Ridham.",
    avatar: Avatar5,
    name: "Ananya Srivastava",
    profession: "Data Scientist",
  },
  {
    id: 6,
    quote: "Best developer in the world!",
    avatar: Avatar6,
    name: "Alexandria",
    profession: "Brand Influencer",
  },
];

export default data;
