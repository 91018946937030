import { SiAdobexd } from "react-icons/si";
import { RiReactjsLine } from "react-icons/ri";
import { FaServer } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import { FaPython, FaDatabase, FaChartLine, FaDataSheet } from "react-icons/fa";
import { SiDataiku } from "react-icons/si";

const data = [
  {
    id: 1,
    icon: <FaChartLine />,
    title: "Data Science",
    desc: "",
  },
  {
    id: 2,
    icon: <FaPython />,
    title: "Python Development",
    desc: "",
  },
  {
    id: 3,
    icon: <FaDatabase />,
    title: "Data Engineering",
    desc: "",
  },
  {
    id: 4,
    icon: <FaChartLine />,
    title: "Data analytics",
    desc: "",
  },
];

export default data;
