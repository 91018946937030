import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineDribbble } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";

const data = [
  { id: 1, link: "https://x.com/ridhampuri65076", icon: <AiOutlineTwitter /> },
  { id: 2, link: "https://instagram.com", icon: <AiOutlineInstagram /> },
  { id: 3, link: "https://github.com/ritz-bot", icon: <AiFillGithub /> },
];

export default data;
