import AboutImage from "../../assets/about.jpg";
import CV from "../../assets/cv.pdf";
import { HiDownload } from "react-icons/hi";
import data from "./data";
import Card from "../../components/Card";
import "./about.css";

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
      <div className="container about__container">
        <div className="about__left">
          <div className="about__portrait">
            <img src={AboutImage} alt="About Image" />
          </div>
        </div>
        <div className="about__right">
          <h2>About Me</h2>
          <div className="about__cards">
            {data.map((item) => (
              <Card key={item.id} className="about__card">
                <span className="about__card-icon">{item.icon}</span>
                <h5>{item.title}</h5>
                <small>{item.desc}</small>
              </Card>
            ))}
          </div>
          <p>
            Hi, my name is Ridham Puri from Punjab, India. I hold a Bachelor's
            degree in Computer Science and am currently pursuing a Master's
            degree in the same domain. My academic background has provided me
            with a strong foundation in both theoretical and practical aspects
            of computing. I am passionate about full stack development and have
            a keen interest in deep learning and machine learning.{" "}
          </p>
          <p>
            Throughout my studies, I have engaged in numerous projects that span
            various aspects of software development and data science. My
            experience includes developing end-to-end applications, designing
            and implementing machine learning models, and leveraging deep
            learning techniques to solve complex problems. I am dedicated to
            continuously expanding my knowledge and skills in these rapidly
            evolving fields. My goal is to combine my expertise in full stack
            development with cutting-edge advancements in artificial
            intelligence to create innovative and impactful solutions.
          </p>
          <a href={CV} download className="btn primary">
            Download CV <HiDownload />
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
